// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._style-module__animation_container___3nMJZ{margin:100px auto;width:500px;text-align:center}._style-module__progress___3t7WS{padding:0px;background:rgba(0,0,0,.25);border-radius:6px;box-shadow:inset 0 1px 2px rgba(0,0,0,.25),0 1px rgba(255,255,255,.08)}._style-module__progress_bar___YeqOJ{height:18px;background-color:#ee303c;border-radius:4px;transition:.4s linear;transition-property:width,background-color}._style-module__progress_striped___3CdDo ._style-module__progress_bar___YeqOJ{background-color:#fcbc51;width:100%;background-color:#f44336;animation:_style-module__progressAnimationStrike___xQKvH 10s}@keyframes _style-module__progressAnimationStrike___xQKvH{from{width:0}to{width:98%}}", "",{"version":3,"sources":["/tmp/build_d90ea8a0/app/javascript/components/common/_style.module.scss"],"names":[],"mappings":"AAAA,4CACE,iBAAA,CACA,WAAA,CACA,iBAAA,CAGF,iCACE,WAAA,CACA,0BAAA,CACA,iBAAA,CACA,sEAAA,CAGF,qCACE,WAAA,CACA,wBAAA,CACA,iBAAA,CACA,qBAAA,CACA,0CAAA,CAGF,8EACE,wBAAA,CACA,UAAA,CACA,wBAAA,CACA,4DAAA,CAGF,0DACE,KAAA,OAAA,CACA,GAAA,SAAA,CAAA","file":"_style.module.scss","sourcesContent":[".animation_container {\n  margin: 100px auto;\n  width: 500px;\n  text-align: center;\n}\n\n.progress {\n  padding: 0px;\n  background: rgba(0, 0, 0, 0.25);\n  border-radius: 6px;\n  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);\n}\n\n.progress_bar {\n  height: 18px;\n  background-color: #ee303c;\n  border-radius: 4px;\n  transition: 0.4s linear;\n  transition-property: width, background-color;\n}\n\n.progress_striped .progress_bar {\n  background-color: #FCBC51;\n  width: 100%;\n  background-color: #F44336;\n  animation: progressAnimationStrike 10s;\n}\n\n@keyframes progressAnimationStrike {\n  from { width: 0 }\n  to   { width: 98% }\n}\n"]}]);
// Exports
exports.locals = {
	"animation_container": "_style-module__animation_container___3nMJZ",
	"progress": "_style-module__progress___3t7WS",
	"progress_bar": "_style-module__progress_bar___YeqOJ",
	"progress_striped": "_style-module__progress_striped___3CdDo",
	"progressAnimationStrike": "_style-module__progressAnimationStrike___xQKvH"
};
module.exports = exports;
