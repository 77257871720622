import React, { useState, useEffect, useRef }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import { OptionsCommon } from 'components/show_drawing/redraw_form/index.js';

const OptionLenghtWithInFractions =  ({ id, drawing, option, unit, optionsUserChanged, addToOptionsUserChanged, setRedrawRequested }) => {
  const [valueWrapped, setValueWrapped] = useState(0);
  const [fraction, setFraction] = useState(0);
  const inputRef = useRef();

  const formattedValue = (value) => {
    let toFormat = parseFloat(value);
    let formatted = (Number.isNaN(toFormat) ? 0 : toFormat).toFixed(0);

    return formatted;
  }

  const redrawOnChange = false;
  const [isVisible, value, valueAutoCalculated, recommendedValue, onChange, showAbcHelperLine, _changed, highlighted] = OptionsCommon( { formattedValue, id, drawing, option, unit, optionsUserChanged, addToOptionsUserChanged, setRedrawRequested, redrawOnChange } )

  const formatValueDecimal = (e) => {
    let formattedValueRes = formattedValue(e.target.value)
    onChange( { target: { value: formattedValueRes} } );
  }

  const onFractionChanged = (e) => {
     setFraction( () => e.target.value )
  }

  const [fractions64, setFractions64] = useState( [] )
  const [fractions100, setFractions100] = useState([] )
  const select = () => {
    return <select className={styles.select_fraction} style={{display: 'inline'}} value={fraction} onChange={ onFractionChanged }>
      <optgroup label="1/64 ...">
      { fractions64.length > 0 && fractions64.map( (fraction) => {
        return <option value={fraction}> {fraction} </option>
      } )}
      </optgroup>

      <optgroup label="1/100 ...">
      { fractions100.length > 0 && fractions100.map( (fraction) => {
        return <option value={fraction}> {fraction} </option>
      } )}
      </optgroup>

    </select>
  }

  // useEffect
    useEffect(() => {
      setValueWrapped( () => `${value}+${fraction}` )
    }, [value, fraction])
  // useEffect
  return (
     <div className={`${styles.row}`}>
      <div className={`input-group ${highlighted ? styles.highlighted : ''}`}>
        <span className={`input-group-addon input-group-addon-${window.locale} ${styles.input_add_on}`}>
          {option.presented_name}
        </span>

          <table className="table" style={{ padding: "0px", margin: "0px" }}>
            <tbody>
            <tr>
              <td style={{ padding: "0px", margin: "0px" }}>
              <input
                type="text"
                value={value}
                onChange={onChange}
                onBlur={formatValueDecimal}
                readOnly={option.readonly}
                autoComplete="off"
                onFocus={showAbcHelperLine}
                ref={inputRef}
                className={`${styles.input_text_align} drawing-option-input form-control ${ option.presented_name.length > 20 ? ' drawing-option-input-wide' : '' }`} aria-label=""
              /> </td>

            <td style={{ padding: "0px", margin: "0px" }}> {select()} </td>
            </tr>
            </tbody>
          </table>
          <input type="hidden" name={option.name} value={valueWrapped} />

        <span className="input-group-addon font13">in </span>

      </div>
    </div>)
}

export default OptionLenghtWithInFractions;
