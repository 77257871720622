import React, { useState, useEffect, useRef }  from 'react';
import { MaterialChooser, MaterialGroupChooser, PrintingMethodChooser, DrawingOptions, RedrawButton, TagsAsOptions, SelectFlap, RedrawOnAllOptionChangesComplete } from 'components/show_drawing/redraw_form/index.js';
import { useForm  } from "react-hook-form";
import ToolTip from 'components/show_drawing/ToolTip.jsx';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import Constants from 'components/show_drawing/Constants.jsx';
import MyEventListener from 'components/common/MyEventListener.jsx'

const RedrawForm = ( { id, userDowloadId, drawing, unit, setUnit, formRef, theme, setRedrawRequested, optionsUserChanged, addToOptionsUserChanged, removeFromOptionsUserChanged, redrawInProgress, setId, debug, GetRequestPath, currentDrawingOptions, currentUserDrawingOptions, addAddOnToDieline, removeAddOnFromDieline, addOnsAsQueryStr, advancedOptionsVisible, setAdvancedOptionsVisible, resetEnabled, setResetEnabled, dimensionTextschecked, setDimensionTextschecked, initialOptions } ) => {
  const {register, handleSubmit, clearErrors, trigger, formState: { errors }} =  useForm();
  const [maxHeight, setMaxHeight] = useState(Constants.SVG_maxHeight);
  const [minHeight, setMinHeight] = useState(Constants.SVG_minHeight);
  const [redrawBtnClass, setRedrawBtnClass] = useState('');
  const myRef = useRef();

  const onAbcLinesReady = () => {
    if( $("#svg_abc_education").length) {
      var panZoomInstanceAbc = svgPanZoom('#svg_abc_education', {
        zoomEnabled: false,
        controlIconsEnabled: false,
        fit: true,
        center: true,
        minZoom: 0.5,
        maxZoom: 5.2,
        panEnabled: true,
        contain: true
      })
      $(".dmt2").hide();
      $(".abc2").show();
      $(".abc2").css("opacity", 1);
      panZoomInstanceAbc.zoom(0.95);
    } else {
      setTimeout(onAbcLinesReady, 100);
    }
  }

  const clearLocalStorage = () => {
    if( !isLocalStorageSupported() ) { return }
    if( drawing.main_id == undefined ) { return }
    let keyForLocalStorage = `fo_${drawing.main_id}_${unit}`;
    localStorage.removeItem(keyForLocalStorage);

    if(userDowloadId != undefined) {
      window.history.pushState( {} , drawing.page_title, drawing.path );
      location.reload();
    } else {
      emitEvent(Constants.drawing_reset_form, { id: id })
    }
  }

  const reset = (e) => {
    e.preventDefault();
    clearLocalStorage();

  }

  const onDownloadFinishedMsg = (msg) => {
    if(msg.detail.id == id) {
      clearLocalStorage()
    }
  }

  const resetLink = () => {
    return <div className={styles.reset_link}>
      <span className={styles.floatRight}>
        <small><a href="#" onClick={reset}> <i className="zmdi zmdi-undo"></i> { t('drawing.reset_form') } </a> </small>
      </span>
      <br />
      <br />
    </div>
  }

  const updateRedrawBtnClass = () => {
    setTimeout(updateRedrawBtnClass, 500);

    if ($(myRef.current).height() == undefined) { setRedrawBtnClass( () => ''); return; }
    var padding = resetEnabled ? 60 : 40
    if (padding + $(myRef.current).height() +  $("#top-nav-header").height() < minHeight)  {
      setRedrawBtnClass( () =>  styles.bottom); return;
    }

    setRedrawBtnClass( () => '');
  }

  const heightAdjusted = useRef(false);

  // useEffect
  useEffect(updateRedrawBtnClass, [resetEnabled, advancedOptionsVisible])
  useEffect( () => {
    let resetArea = 40;

    let adjust = () => {
      if (resetEnabled) {
        setMaxHeight( (prev) => prev + resetArea  )
        setMinHeight( (prev) => prev + resetArea  )
        heightAdjusted.current = true;
      } else {
        if (heightAdjusted.current) {
          setMaxHeight( (prev) => prev - resetArea  )
          setMinHeight( (prev) => prev - resetArea  )
        }
      }
    }

    adjust();

  }, [resetEnabled])
  // useEffect end

  return (
    <div>
    <div className={styles.form_container} style={{ maxHeight: maxHeight, minHeight: minHeight }} >

      <div ref={myRef}>
        <RedrawOnAllOptionChangesComplete
          id={id}
          userDowloadId={userDowloadId}
          unit={unit}
          drawing={drawing}
          formRef={formRef}
          debug={debug}
          setResetEnabled={setResetEnabled}
          setRedrawRequested={setRedrawRequested}
          initialOptions={initialOptions}
        />

        <div style={{marginBottom: '20px'}}>
          <strong>
            {t('three_d.options_and_dimensions')}
            </strong>
          <ToolTip title={drawing.abc_lines_help_text} toolTipKey={`abc_lines_${id}`} onReady={onAbcLinesReady} />
        </div>

        <div className={styles.row_unit}>
          <label className={styles.label}> { t('my_drawings.options_unit_short') } </label>
          <select value={unit} onChange={(e) => setUnit(e.target.value)} className={styles.select}>
            <option value="mm"> { t('drawing.unit_mm') } </option>
            {false && <option value="infr"> { t('drawing.unit_infr') } </option> }
            <option value="in"> { t('drawing.unit_in') } </option>
          </select>
        </div>

        <input type="hidden" name="unit" value={unit} />
        <input type="hidden" name="user_unit" value={unit} />
        <input type="hidden" name="id" value={id} />

        <MaterialGroupChooser id={id} setId={setId} unit={unit} drawing={drawing}/>

        <MaterialChooser id={id} unit={unit} drawing={drawing} register={register} errors={errors}/>

        {drawing.ask_offset && <PrintingMethodChooser id={id} unit={unit} drawing={drawing} /> }

        <TagsAsOptions id={id} setId={setId} drawing={drawing} theme={theme} debug={debug} />

        <DrawingOptions
          id={id}
          unit={unit}
          drawing={drawing}
          register={register}
          errors={errors}
          optionsUserChanged={optionsUserChanged}
          addToOptionsUserChanged={addToOptionsUserChanged}
          removeFromOptionsUserChanged={removeFromOptionsUserChanged}
          debug={debug}
          setMaxHeight={setMaxHeight}
          setRedrawRequested={setRedrawRequested}
          customerAddOnsEnabled={drawing.customer_add_ons_enabled}
          GetRequestPath={GetRequestPath}
          currentDrawingOptions={currentDrawingOptions}
          addAddOnToDieline={addAddOnToDieline}
          removeAddOnFromDieline={removeAddOnFromDieline}
          addOnsAsQueryStr={addOnsAsQueryStr}
          advancedOptionsVisible={advancedOptionsVisible}
          setAdvancedOptionsVisible={setAdvancedOptionsVisible}
          dimensionTextschecked={dimensionTextschecked}
          setDimensionTextschecked={setDimensionTextschecked}
        />

        <div className={redrawBtnClass}>
          <RedrawButton id={id} unit={unit} setRedrawRequested={setRedrawRequested} redrawInProgress={redrawInProgress} />
          { resetEnabled && resetLink() }
        </div>
      </div>

    </div>

    </div>
  )
}

export default RedrawForm;
